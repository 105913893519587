<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <el-breadcrumb separator="/" class="breadcrumb">
        <el-breadcrumb-item>后台管理端</el-breadcrumb-item>
        <el-breadcrumb-item>管理首页</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="container bg flex-1 flex-v">
        <div class="scrollybg h100">
          <div class="list">
            <div class="item" v-for="item in 12">
              <div class="top ellipsis">
                <img
                  src="../assets/images/icon_07.png"
                  class="icon"
                  alt=""
                />中国农业银行浙江路分行
              </div>
              <div class="box">
                <div class="row">
                  <h6 class="smtit"><span>能源内容</span></h6>
                  <div class="txt">
                    默认站内、APP告警；短信、EMail为可选配置项；
                  </div>
                </div>
                <div class="row">
                  <h6 class="smtit"><span>系统内容</span></h6>
                  <div class="txt">
                    默认站内、APP告警；短信、EMail为可选配置项；默认站内、APP告警；短信、EMail为可选配置项；
                  </div>
                </div>
                <div class="row">
                  <h6 class="smtit"><span>产品内容</span></h6>
                  <div class="txt">
                    默认站内、APP告警；短信、EMail为可选配置项；
                  </div>
                </div>
                <div class="row">
                  <h6 class="smtit"><span>服务内容</span></h6>
                  <div class="txt">
                    默认站内、APP告警；短信、EMail为可选配置项；
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
export default {
  data() {
    return {
      form: {
        username: "",
        role: "",
        phone: "",
        status: "",
      },
      tableData: [
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "yisuoding",
          biangeng: "jiesuo",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "zhengchang",
          biangeng: "suoding",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "daijihuo",
          biangeng: "suoding",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "yisuoding",
          biangeng: "jiesuo",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "zhengchang",
          biangeng: "suoding",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "daijihuo",
          biangeng: "suoding",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "daijihuo",
          biangeng: "suoding",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "daijihuo",
          biangeng: "suoding",
        },
        {
          zhanghao: "13311112222",
          yonghuming: "李老师",
          xingbie: "女",
          nianling: "33",
          juese: "运维工程师",
          xiangmu: "邯钢公司",
          zhuangtai: "daijihuo",
          biangeng: "suoding",
        },
      ],
      currentPage: 1,
    };
  },
  components: {
    Head,
    Sidebar,
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.container {
  padding: 30px 20px 30px 30px;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  padding-right: 5px;
}

.list .item {
  border-radius: 3px;
  border: 1px solid #565b6e;
  margin: 0 0 1% 1%;
  width: 24%;
}

.list .top {
  font-size: 18px;
  line-height: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 20px;
  color: #fff;
}

.list .icon {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.list .box {
  padding: 15px 20px;
}

.list .row {
  margin-bottom: 15px;
}

.list .smtit {
  font-size: 16px;
  color: #ccd9e5;
  line-height: 26px;
  position: relative;
  padding: 0 10px;
  margin-bottom: 8px;
}

.list .smtit span {
  position: relative;
  z-index: 5;
}

.list .smtit::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 10px;
  background: linear-gradient(to right, #9443de, rgba(148, 67, 222, 0.1));
}

.list .txt {
  font-size: 14px;
  color: #ccd9e5;
}
</style>
